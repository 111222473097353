body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Mossport; /* Гарнитура шрифта */
  src: url(static/Mossport.otf); /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: Gotham Pro; /* Гарнитура шрифта */
  src: url(static/GothaProReg.otf); /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: Gotham ProBold; /* Гарнитура шрифта */
  src: url(static/GothaProMed.otf); /* Путь к файлу со шрифтом */
}
